<template>
  <div class="homePage">
    <div class="content-top"></div>
    <div class="content">
      <div class="title">Please enter your purchase requirement</div>
      <div class="body">
        <div class="form">
          <Form
            ref="form"
            :model="inquireForm"
            class="register-form"
            :label-width="250"
          >
            <Row :gutter="20">
              <i-col span="16">
                <FormItem
                  label="Product Name"
                  :rules="required"
                  prop="product_name"
                >
                  <Input v-model="inquireForm.product_name" />
                </FormItem>
              </i-col>
            </Row>
            <Row :gutter="20">
              <i-col span="12">
                <FormItem label="Quantity" :rules="required" prop="quantity">
                  <Input v-model="inquireForm.quantity" />
                </FormItem>
              </i-col>
            </Row>
            <Row :gutter="20">
              <i-col span="12">
                <FormItem
                  label="Quality Standard"
                  :rules="required"
                  prop="quality_standard"
                >
                  <Input v-model="inquireForm.quality_standard" />
                </FormItem>
              </i-col>
            </Row>
            <Row :gutter="20">
              <i-col span="12">
                <FormItem label="Application" prop="application">
                  <Input v-model="inquireForm.application" />
                </FormItem>
              </i-col>
            </Row>
            <Row :gutter="20">
              <i-col span="12">
                <FormItem
                  label="Main Specs"
                  :rules="required"
                  prop="main_specs"
                >
                  <Input v-model="inquireForm.main_specs" />
                </FormItem>
              </i-col>
            </Row>
            <Row :gutter="20">
              <i-col span="8">
                <FormItem label="Price Basis">
                  <Select
                    filterable
                    v-model="inquireForm.price_basis"
                    @on-change="selectPrice($event)"
                  >
                    <Option v-for="item in prices" :value="item" :key="item">{{
                      item
                    }}</Option>
                  </Select>
                </FormItem>
              </i-col>
              <i-col span="8" class="price" v-show="'' != price_type">
                <FormItem
                  label="Address"
                  v-if="inquireForm.price_basis == 'Others'"
                >
                  <Input
                    v-model="inquireForm.price_basis_value"
                    placeholder="City, State and Country"
                  />
                </FormItem>
                <FormItem label="Price" v-else>
                  <Input v-model="inquireForm.price_basis_value" />
                </FormItem>
              </i-col>
            </Row>
            <Row :gutter="20">
              <i-col span="12">
                <FormItem label="Packaging" :rules="required" prop="packaging">
                  <Input v-model="inquireForm.packaging" />
                </FormItem>
              </i-col>
            </Row>
            <Row :gutter="20">
              <i-col span="8">
                <FormItem
                  label="Shipment Date"
                  :rules="required"
                  prop="shipment_date"
                >
                  <DatePicker
                    type="date"
                    v-model="inquireForm.shipment_date"
                    format="yyyy-MM-dd"
                    placeholder="Please select a date"
                  ></DatePicker>
                </FormItem>
              </i-col>
              <i-col span="8">
                <FormItem label="Price Idea" prop="price_idea">
                  <Input v-model="inquireForm.price_idea" />
                </FormItem>
              </i-col>
            </Row>
            <Row :gutter="20">
              <i-col span="16">
                <FormItem
                  label="Required Documents"
                  :rules="required"
                  prop="required_documents"
                >
                  <Input v-model="inquireForm.required_documents" />
                </FormItem>
              </i-col>
            </Row>
            <Row :gutter="20">
              <i-col span="16">
                <FormItem label="Note" prop="note" :class="inputNote">
                  <Input
                    v-model="inquireForm.note"
                    type="textarea"
                    placeholder=""
                    :rows="4"
                    :class="inputNote"
                    style="width: 100%;height:100px"
                  />
                </FormItem>
              </i-col>
            </Row>
            <Row :gutter="20">
              <i-col span="12">
                <FormItem
                  label="Expire Date"
                  :rules="required"
                  prop="expire_date"
                >
                  <DatePicker
                    type="date"
                    v-model="inquireForm.expire_date"
                    format="yyyy-MM-dd"
                    placeholder="Please select a date"
                  ></DatePicker>
                </FormItem>
              </i-col>
            </Row>
          </Form>
        </div>
        <div v-if="!mloading" class="btn" @click="handleSubmit('form')">
          Submit
        </div>
        <div v-else class="btn">
          Submitting...
        </div>
      </div>
    </div>
    <div class="content-bottom"></div>
  </div>
</template>

<script>
import api from "@/api";
const { _querySingleSourcing, _editSourcing } = api;
export default {
  name: "sourcing-inquiry-attendee",
  computed: {},
  components: {},
  data() {
    return {
      mobileWidth: window.innerWidth < 800,
      inquireForm: {},
      inputNote: "input-note",
      product_name: "",
      quantity: "",
      quantity_standard: "",
      mloading: false,
      required: [
        {
          required: true,
          message: "Please fill"
        }
      ],
      prices: ["CIF", "FOB", "Delivered", "Others"],
      price_type: "",
      createLoading: false
    };
  },
  // created:{
  //
  // },
  mounted() {
    this.getSourcingInfo();
  },
  methods: {
    sendInquire() {
      this.mloading = true;
      this.inquireForm.id = this.$route.params.id;
      _editSourcing(this.inquireForm)
        .then(() => {
          // if (!this.role) this.showLinkTip();
          this.$Message.success("Update Sourcing Success");
          this.$router.push("/my-sourcingso");
          this.showInquire = false;
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        })
        .finally(() => {
          this.mloading = false;
        });
    },
    getSourcingInfo() {
      const id = this.$route.params.id;
      this.createLoading = true;
      _querySingleSourcing({ id })
        .then(({ data }) => {
          this.inquireForm = data;
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        })
        .finally(() => {
          this.createLoading = false;
        });
    },
    selectPrice(e) {
      this.price_type = e;
    },

    handleSubmit(name) {
      this.$refs[name].validate(valid => {
        if (valid) {
          this.sendInquire();
        }
      });
    }
  },
  filters: {}
};
</script>

<style lang="less" scoped>
@media (min-width: 1600px) {
}
@media (max-width: 1600px) and (min-width: 1400px) {
  .content {
    width: 1050px !important;
  }
  .form .register-form {
    left: 120px !important;
  }
  .btn {
    left: 375px !important;
  }
}
@media (max-width: 1400px) and (min-width: 1200px) {
  .content {
    width: 950px !important;
  }
  .form .register-form {
    left: 110px !important;
  }
  .btn {
    left: 330px !important;
  }
}
@media (max-width: 1200px) {
  .content {
    width: 900px !important;
  }
  .form .register-form {
    left: 110px !important;
  }
  .btn {
    left: 320px !important;
  }
}
.homePage {
  background: #f4f4f4;
  margin-top: 128px;
}
.sc-width {
  margin: 0 auto;
}
.content-top {
  background: #f4f4f4;
  height: 30px;
}
.content-bottom {
  background: #f4f4f4;
  height: 30px;
}
.content {
  background: #ffffff;
  box-shadow: 0px 0px 0px 6px rgba(239, 132, 45, 0.3);
  border-radius: 5px;
  width: 1200px;
  height: 1032px;
  margin: 20px auto 5px auto;
  /*position: relative;*/
  /*top: 56px;*/
}
.title {
  font-size: 24px;

  font-weight: 700;
  color: #ef7f21;
  line-height: 18px;
  position: relative;
  top: 64px;
  left: 96px;
}
.form {
  margin: 0 auto;
  .item {
    display: flex;
    justify-content: center;
  }
  .register-form {
    position: relative;
    left: 150px;
    top: 154px;
  }
}
.btn {
  width: 290px;
  height: 40px;
  background: linear-gradient(90deg, #f5a210, #ef8126);
  border-radius: 5px;
  font-size: 20px;

  font-weight: 500;
  color: #fefefe;
  line-height: 18px;
  text-align: center;
  padding-top: 10px;
  position: relative;
  top: 200px;
  left: 400px;
  cursor: pointer;
  cursor: pointer;
}
</style>
<style scoped>
.form-box >>> .ivu-card-bordered {
  border: none;
}
.register-form >>> .ivu-input {
  /*width:400px;*/
  height: 36px;
}
.register-form >>> .ivu-select-input {
  /*width:400px;*/
  height: 36px;
}
.register-form >>> .ivu-form-item-label {
  font-size: 18px;

  font-weight: 500;
  color: #292929;
  line-height: 18px;
}
.register-form >>> .input-note textarea {
  height: 100px;
}
.ivu-message {
  /*top: 200px;*/
}
</style>
